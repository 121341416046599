import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper } from '@mui/material';
import {
  IconUsers,
  IconBriefcase,
  IconMail,
  IconStar,
  IconMessageCircle,
  IconLink,
  IconSun,
  IconMoon,
  IconTruck,
  IconYoga,
  IconHeart,
  IconHome,
  IconFriends
} from '@tabler/icons-react';

const iconMapping = {
  employees: IconUsers,
  clients: IconBriefcase,
  projects: IconMail,
  events: IconStar,
  payroll: IconMessageCircle,
  reports: IconLink,
  sun: IconSun,
  moon: IconMoon,
  briefcase: IconBriefcase,
  truck: IconTruck,
  yoga: IconYoga,
  heart: IconHeart,
  users: IconUsers,
  home: IconHome,
  friends: IconFriends
};

const TopCard = ({ 
  title, 
  value, 
  hours, 
  icon, 
  backgroundColor = '#fff', 
  color = '#000' 
}) => {
  const IconComponent = iconMapping[icon];
  if (!IconComponent) return null; // Ensure the icon is valid

  return (
    <Paper style={{ padding: '20px', borderRadius: '10px', backgroundColor: backgroundColor, color: color }}>
      <Box display="flex" alignItems="center">
        <IconComponent size={40} />
        <Box ml={2}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h4">{value}</Typography>
          <Typography variant="body2" style={{ fontSize: '0.8em' }}>{hours}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

TopCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default TopCard;
