import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { logEvent } from './analytics';

const WhatIfScenarios = ({ activity, frequency = 1, onFrequencyChange, adjusted }) => {
  const [localFrequency, setLocalFrequency] = useState(frequency);

  useEffect(() => {
    onFrequencyChange(activity, localFrequency);
  }, [localFrequency]);

  const handleChange = (value) => {
    setLocalFrequency(value);
    logEvent('User', 'Change Frequency Slider', `Activity: ${activity}, New Frequency: ${value}`);
  };

  return (
    <Box mt={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1" style={{ color: 'inherit' }}>
            {`${activity.charAt(0).toUpperCase() + activity.slice(1)} per year: ${localFrequency}`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Slider
            value={localFrequency}
            min={0}
            max={52}
            step={1}
            onChange={handleChange}
            handleStyle={{
              borderColor: '#007bff',
              backgroundColor: '#007bff',
              height: 24,
              width: 24,
              marginLeft: -12,
              marginTop: -12,
            }}
            trackStyle={{ backgroundColor: '#007bff' }}
            railStyle={{ backgroundColor: '#ddd' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhatIfScenarios;
