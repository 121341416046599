// src/Theme.js
import { createTheme } from '@mui/material/styles';
import typography from './Typography';
import { shadows } from './Shadows';
import { LightThemeColors } from './LightThemeColors';
import components from './Components';

const themeOptions = LightThemeColors.find((theme) => theme.name === 'BLUE_THEME').palette;

const theme = createTheme({
  palette: {
    ...themeOptions,
  },
  typography,
  shadows,
});

theme.components = components(theme);

export default theme;
