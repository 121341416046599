import React, { forwardRef, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { logEvent } from './analytics';
import './shareableSummary.css';

// Helper function to format numbers with commas and two decimal places
const formatNumber = (number) => {
  return Number(number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const ShareableSummary = forwardRef(({ results = {}, data = {}, visible }, ref) => {
  useEffect(() => {
    if (visible) {
      logEvent('ShareableSummary', 'Summary Visible', 'The shareable summary is visible');
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <Box ref={ref} className="shareable-summary-container">
      <Box className="shareable-summary-content">
        <Box className="hero-section summary-hero">
          <Typography variant="h4" component="h1" className="hero-title">
            My Life in Numbers
          </Typography>
        </Box>
        <Grid container spacing={3} className="summary-grid">
          {results.totalWakingHours && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Total Waking Hours
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.totalWakingHours)}
              </Typography>
            </Grid>
          )}
          {results.totalWorkHours && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Total Work Hours
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.totalWorkHours)}
              </Typography>
            </Grid>
          )}
          {results.totalCommuteHours && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Total Commute Hours
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.totalCommuteHours)}
              </Typography>
            </Grid>
          )}
          {results.totalPrayerHours && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Total Prayer Hours
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.totalPrayerHours)}
              </Typography>
            </Grid>
          )}
          {results.totalPersonalActivityHours && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Total Personal Activity Hours
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.totalPersonalActivityHours)}
              </Typography>
            </Grid>
          )}
          {results.totalFamilyTime && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Total Family Time
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.totalFamilyTime)}
              </Typography>
            </Grid>
          )}
          {results.individualTimePerImmediateFamilyMember && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Individual Time Per Immediate Family Member
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.individualTimePerImmediateFamilyMember)}
              </Typography>
            </Grid>
          )}
          {results.individualTimePerExtendedFamilyMember && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Individual Time Per Extended Family Member
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.individualTimePerExtendedFamilyMember)}
              </Typography>
            </Grid>
          )}
          {results.individualTimePerCloseFriend && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Individual Time Per Close Friend
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.individualTimePerCloseFriend)}
              </Typography>
            </Grid>
          )}
          {results.age && (
            <Grid item xs={12} className="summary-item">
              <Typography variant="h6" component="h2" className="summary-title">
                Age
              </Typography>
              <Typography className="summary-value">
                {formatNumber(results.age)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Typography className="summary-footer">
          Generated by ourdays.io
        </Typography>
      </Box>
    </Box>
  );
});

export default ShareableSummary;
