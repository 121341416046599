// utils.js

export const calculateAge = (birthday) => {
  const today = new Date();
  const birthDate = new Date(birthday);
  const ageDiff = today - birthDate;
  return Math.floor(ageDiff / (365.25 * 24 * 60 * 60 * 1000)); // Calculate age in years including days
};

export const calculateResults = (data, birthday) => {
  const age = calculateAge(birthday);

  // Ensure all values are properly initialized and not NaN
  const lifeExpectancy = Number(data.lifeExpectancy || 0);
  const sleepHours = Number(data.sleepHours || 0);
  const workHours = Number(data.workHours || 0);
  const commuteTime = Number(data.commuteTime || 0);
  const prayerTime = Number(data.prayerTime || 0);
  const customActivities = Number(data.customActivities || 0);
  const immediateFamilyMembers = Number(data.immediateFamilyMembers || 1); // Avoid division by zero
  const extendedFamilyMembers = Number(data.extendedFamilyMembers || 1); // Avoid division by zero
  const closeFriends = Number(data.closeFriends || 1); // Avoid division by zero

  if (isNaN(age) || age < 0 || age > lifeExpectancy) {
    console.error('Invalid age detected:', age);
    return {}; // Return early with an empty object if age is invalid
  }

  const workYearsLeft = Math.min(Math.max(data.retirementAge - age, 0), lifeExpectancy - age);
  const totalWorkHours = workYearsLeft * 365.25 * workHours;
  const totalWakingHours = (lifeExpectancy - age) * 365.25 * (24 - sleepHours);
  const totalCommuteHours = workYearsLeft * commuteTime * 365.25;
  const totalPrayerHours = prayerTime * (lifeExpectancy - age) * 365.25;
  const totalPersonalActivityHours = customActivities * (lifeExpectancy - age) * 365.25;
  const totalFamilyTime = totalWakingHours - (totalWorkHours + totalCommuteHours + totalPrayerHours + totalPersonalActivityHours);

  const individualTimePerImmediateFamilyMember = immediateFamilyMembers ? totalFamilyTime / immediateFamilyMembers : 0;
  const individualTimePerExtendedFamilyMember = extendedFamilyMembers ? totalFamilyTime / extendedFamilyMembers : 0;
  const individualTimePerCloseFriend = closeFriends ? totalFamilyTime / closeFriends : 0;

  return {
    totalWakingHours,
    totalWorkHours,
    totalCommuteHours,
    totalPrayerHours,
    totalPersonalActivityHours,
    totalFamilyTime,
    individualTimePerImmediateFamilyMember,
    individualTimePerExtendedFamilyMember,
    individualTimePerCloseFriend,
    totalSleepingHours: sleepHours * (lifeExpectancy - age) * 365.25,
    age,
  };
};

export const formatTime = (hours) => {
  const totalSeconds = Math.floor(hours * 3600);
  const years = (totalSeconds / (3600 * 24 * 365.25)).toFixed(2);
  const months = (totalSeconds / (3600 * 24 * 30.44)).toFixed(2);
  const weeks = (totalSeconds / (3600 * 24 * 7)).toFixed(2);
  const days = (totalSeconds / (3600 * 24)).toFixed(2);

  const hoursPart = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return {
    years: Number(years).toLocaleString(),
    months: Number(months).toLocaleString(),
    weeks: Number(weeks).toLocaleString(),
    days: Number(days).toLocaleString(),
    hours: hoursPart.toLocaleString().padStart(2, '0'),
    minutes: minutes.toLocaleString().padStart(2, '0'),
    seconds: seconds.toLocaleString().padStart(2, '0'),
  };
};

export const loadFromLocalStorage = (key, defaultValue) => {
  const savedData = localStorage.getItem(key);
  return savedData ? JSON.parse(savedData) : defaultValue;
};

export const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    if (error instanceof DOMException && error.name === 'QuotaExceededError') {
      console.warn('Local storage quota exceeded. Clearing old data...');
      localStorage.clear(); // This is a simple approach, you may want to implement a more sophisticated strategy.
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      console.error('Failed to save to local storage', error);
    }
  }
};
