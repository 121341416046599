import React, { useEffect, useState, useRef } from 'react';
import { Box, CssBaseline, Container, Grid, useMediaQuery, useTheme, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, Paper } from '@mui/material';
import { IconBulb } from '@tabler/icons-react';
import UserForm from './UserForm';
import Results from './Results';
import ShareableSummary from './ShareableSummary';
import { calculateResults, loadFromLocalStorage, saveToLocalStorage, calculateAge } from './utils';
import { toPng } from 'html-to-image';
import ReactMarkdown from 'react-markdown'; // Import for markdown rendering
import './theme.css';
import { initGA, logPageView, logEvent } from './analytics';

const FIXED_LIFE_EXPECTANCY = 79;
const BUFFER_YEARS = 3;

function App() {
  const [data, setData] = useState(loadFromLocalStorage('data', {
    lifeExpectancy: '',
    sleepHours: '',
    workHours: '',
    commuteTime: '',
    retirementAge: '',
    immediateFamilyMembers: '',
    prayerTime: '',
    extendedFamilyMembers: '',
    closeFriends: '',
    customActivities: '',
  }));
  const [birthday, setBirthday] = useState(localStorage.getItem('birthday') || '');
  const [results, setResults] = useState(loadFromLocalStorage('results', {}));
  const [exceeds24Hours, setExceeds24Hours] = useState(false);
  const [countdown, setCountdown] = useState(loadFromLocalStorage('countdown', {}));
  const [summaryVisible, setSummaryVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calculationDone, setCalculationDone] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [frequencies, setFrequencies] = useState([]);
  const [aiModalOpen, setAiModalOpen] = useState(false); // State to control modal
  const [aiIdeas, setAiIdeas] = useState(''); // State to hold AI-generated ideas
  const [aiLoading, setAiLoading] = useState(false); // State to control loading spinner
  const shareableRef = useRef(null);
  const resultsRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    initGA('G-0HNJ6MVVGS'); 
    logPageView();
  }, []);

  useEffect(() => {
    saveToLocalStorage('data', data);
    saveToLocalStorage('birthday', birthday);
  }, [data, birthday]);

  useEffect(() => {
    const totalHoursPerDay = Number(data.sleepHours) + Number(data.workHours) + Number(data.commuteTime) + Number(data.prayerTime) + Number(data.customActivities);
    setExceeds24Hours(totalHoursPerDay > 24);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (Object.keys(prevCountdown).length === 0) return prevCountdown;
        const newCountdown = { ...prevCountdown };
        Object.keys(newCountdown).forEach((key) => {
          newCountdown[key] = Math.max(newCountdown[key] - 1 / 3600, 0);
        });
        saveToLocalStorage('countdown', newCountdown);
        return newCountdown;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleBirthdayChange = (e) => {
    setBirthday(e.target.value);
  };

  const calculateTotalExperiences = (yearsLeft, frequency) => {
    const effectiveYears = yearsLeft < 0 ? BUFFER_YEARS : yearsLeft;
    return Math.max(0, Math.floor(effectiveYears * frequency));
  };

  const handleCalculate = async (additionalFields, frequencies) => {
    setLoading(true);
    setCalculationDone(false);

    setTimeout(async () => {
      const numericData = {
        ...data,
        lifeExpectancy: Number(data.lifeExpectancy),
        sleepHours: Number(data.sleepHours),
        workHours: Number(data.workHours),
        commuteTime: Number(data.commuteTime),
        retirementAge: Number(data.retirementAge), // Convert retirementAge to number
        immediateFamilyMembers: Number(data.immediateFamilyMembers),
        prayerTime: Number(data.prayerTime),
        extendedFamilyMembers: Number(data.extendedFamilyMembers),
        closeFriends: Number(data.closeFriends),
        customActivities: Number(data.customActivities),
      };

      console.log("Numeric Data:", numericData);
      console.log("Birthday:", birthday);

      const newResults = calculateResults(numericData, birthday);
      console.log("Calculated Age:", calculateAge(birthday)); // Check if age is calculated correctly
      console.log("Results Data:", newResults); // Check the results object

      setResults(newResults);
      saveToLocalStorage('results', newResults);
      setCountdown(newResults);
      saveToLocalStorage('countdown', newResults);

      const additionalResults = additionalFields.map((field, index) => {
        const age = Number(field.age);
        const lifeExpectancy = numericData.lifeExpectancy || FIXED_LIFE_EXPECTANCY;
        const yearsLeft = lifeExpectancy - age;

        const totalExperiences = {
          phoneCallsLeft: calculateTotalExperiences(yearsLeft, frequencies[index].phoneCalls),
          walksLeft: calculateTotalExperiences(yearsLeft, frequencies[index].walks),
          mealsLeft: calculateTotalExperiences(yearsLeft, frequencies[index].meals),
          vacationsLeft: calculateTotalExperiences(yearsLeft, frequencies[index].vacations),
          visitsLeft: calculateTotalExperiences(yearsLeft, frequencies[index].visits),
          moviesLeft: calculateTotalExperiences(yearsLeft, frequencies[index].movies),
          holidaysLeft: calculateTotalExperiences(yearsLeft, frequencies[index].holidays),
        };

        const result = {
          ...field,
          remainingYears: Math.max(yearsLeft, 0),
          frequencies: totalExperiences,
          adjusted: true,
          message: age > lifeExpectancy ? 'This person is living beyond the average life expectancy.' : '',
        };

        return result;
      });

      console.log("Additional Results:", additionalResults);

      setLoading(false);
      setCalculationDone(true);
      setAdditionalFields(additionalResults);
      setFrequencies(frequencies);
      setTimeout(() => {
        resultsRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);

      logEvent('User', 'Calculate', 'Calculation Done');
    }, 2000);
  };

  const handleExportImage = async () => {
    try {
      setSummaryVisible(true);
      setTimeout(async () => {
        if (shareableRef.current) {
          const dataUrl = await toPng(shareableRef.current, { cacheBust: true, backgroundColor: 'white', width: 1080, height: 1920 });
          if (navigator.share && navigator.canShare({ files: [new File([dataUrl], "life_summary.png", { type: "image/png" })] })) {
            try {
              const blob = await fetch(dataUrl).then(r => r.blob());
              const file = new File([blob], "life_summary.png", { type: blob.type });
              await navigator.share({
                files: [file],
                title: 'Life Summary',
                text: 'Check out my life summary!',
              });
            } catch (error) {
              console.log('Error sharing:', error);
            }
          } else {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'life_summary.png';
            link.click();
          }
          setSummaryVisible(false);
        }
      }, 100);

      logEvent('User', 'Export', 'Export as Image');
    } catch (error) {
      console.error('Error exporting image:', error);
      alert('Failed to export the image. Please try again.');
      setSummaryVisible(false);
    }
  };

  const fetchAiIdeas = async (results) => {
    try {
      setAiLoading(true); // Show loading spinner
      const response = await fetch('https://25mu7ozmkxgfpth6xsz64fxt6m0nxiep.lambda-url.us-west-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ results, additionalFields }),
      });
      const data = await response.json();
      if (data.summary) {
        setAiIdeas(data.summary);
        setAiModalOpen(true); // Open the modal after receiving the summary
      }

      logEvent('User', 'Fetch AI Ideas', 'AI Ideas Fetched');
    } catch (error) {
      console.error('Error fetching AI summary:', error);
    } finally {
      setAiLoading(false); // Hide loading spinner
    }
  };

  const handleFabClick = async () => {
    await fetchAiIdeas(results); // Fetch AI ideas and then open the modal
  };

  const handleCloseAiModal = () => {
    setAiModalOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Container maxWidth="md" className="main-container">
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserForm
                data={data}
                birthday={birthday}
                handleChange={handleChange}
                handleBirthdayChange={handleBirthdayChange}
                handleCalculate={handleCalculate}
                exceeds24Hours={exceeds24Hours}
                loading={loading}
              />
            </Grid>
            {calculationDone && (
              <Grid item xs={12} ref={resultsRef}>
                <Results
                  resultsData={results}
                  countdown={countdown}
                  handleExportImage={handleExportImage}
                  shareableRef={shareableRef}
                  summaryVisible={summaryVisible}
                  additionalFields={additionalFields}
                  lifeExpectancy={data.lifeExpectancy || FIXED_LIFE_EXPECTANCY} // Ensure life expectancy is passed from data
                  frequencies={frequencies}
                  birthday={birthday}
                  data={data}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      <ShareableSummary ref={shareableRef} results={results} data={data} visible={summaryVisible} />
      {calculationDone && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            style={{ position: 'fixed', bottom: 75, right: 16 }}
            onClick={handleFabClick} // Trigger AI ideas fetching and modal opening
          >
            {aiLoading ? <CircularProgress color="inherit" size={24} /> : <IconBulb />}
          </Fab>

          {/* Modal Popup */}
          <Dialog open={aiModalOpen} onClose={handleCloseAiModal} aria-labelledby="ai-ideas-dialog">
            <DialogTitle id="ai-ideas-dialog">AI Ideas</DialogTitle>
            <DialogContent>
              {aiLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                  <CircularProgress />
                </Box>
              ) : (
                <DialogContentText>
                  <ReactMarkdown>{aiIdeas}</ReactMarkdown>
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAiModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default App;
