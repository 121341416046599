import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Alert, Typography, IconButton, CircularProgress } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IconTrash } from '@tabler/icons-react';
import './theme.css';
import { logEvent } from './analytics';
import CustomSlider from './CustomSlider';
import CustomFormLabel from './CustomFormLabel';
import DashboardCard from './DashboardCard';
import CustomSwitch from './CustomSwitch';

const AVERAGE_LIFE_EXPECTANCY = 78.5;
const BUFFER_YEARS = 3;

const predefinedOptions = {
  lifeExpectancy: [70, 75, 80, 85, 90],
  sleepHours: [4, 5, 6, 7, 8, 9, 10],
  workHours: [0, 2, 4, 6, 8, 10, 12],
  commuteTime: [0, 0.5, 1, 1.5, 2, 2.5, 3],
  prayerTime: [0, 0.5, 1, 1.5, 2],
  immediateFamilyMembers: [0, 2, 4, 6, 8, 10, 12],
  extendedFamilyMembers: [0, 2, 4, 8, 12, 16, 20],
  closeFriends: [0, 2, 4, 6, 8, 10],
  leisureTime: [0, 1, 2, 3, 4, 5],
  retirementAge: [50, 55, 60, 65, 70],
  frequencies: [0, 10, 20, 30, 40, 50],
};

const generateMarks = (options) => {
  const step = options.length <= 1 ? 1 : (options[options.length - 1] - options[0]) / 4;
  return options.map((value, index) => ({
    value,
    label: (index === 0 || index === Math.floor(options.length / 2) || index === options.length - 1) ? `${value}` : '',
  }));
};

const UserForm = ({ data, birthday, handleChange, handleBirthdayChange, handleCalculate, exceeds24Hours, loading }) => {
  const parseDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? new Date(1980, 0, 1) : date;
  };

  const [startDate, setStartDate] = useState(parseDate(birthday));
  const [additionalFields, setAdditionalFields] = useState([]);
  const [newPersonName, setNewPersonName] = useState('');
  const [newPersonAge, setNewPersonAge] = useState('');
  const [frequencies, setFrequencies] = useState([]);
  const [toggleStates, setToggleStates] = useState([]);

  useEffect(() => {
    const parsedDate = parseDate(birthday);
    setStartDate(parsedDate);
    handleBirthdayChange({ target: { name: 'birthday', value: parsedDate.toISOString().split('T')[0] } });

    const isFirstVisit = localStorage.getItem('isFirstVisit') === null;
    if (isFirstVisit) {
      localStorage.setItem('isFirstVisit', 'false');
      setTimeout(() => {}, 60000);
    }
  }, [birthday, handleBirthdayChange]);

  const handleDateChange = (date) => {
    if (date && !isNaN(date)) {
      setStartDate(date);
      handleBirthdayChange({ target: { name: 'birthday', value: date.toISOString().split('T')[0] } });
      logEvent('UserForm', 'Date Change', `Birthday changed to: ${date.toISOString().split('T')[0]}`);
    }
  };

  const addNewField = () => {
    setAdditionalFields([...additionalFields, { name: newPersonName, age: newPersonAge }]);
    setFrequencies([...frequencies, {
      phoneCalls: 1,
      walks: 1,
      meals: 1,
      vacations: 1,
      visits: 1,
      movies: 1,
      holidays: 5
    }]);
    setToggleStates([...toggleStates, {
      phoneCalls: true,
      walks: true,
      meals: true,
      vacations: true,
      visits: true,
      movies: true,
      holidays: true
    }]);
    logEvent('UserForm', 'Add Person', `Added person: ${newPersonName}, Age: ${newPersonAge}`);
    setNewPersonName('');
    setNewPersonAge('');
  };

  const handleAdditionalFieldChange = (index, field, value) => {
    const updatedFields = additionalFields.map((item, idx) =>
      idx === index ? { ...item, [field]: value } : item
    );
    setAdditionalFields(updatedFields);
    logEvent('UserForm', 'Additional Field Change', `Field changed: ${field}, Value: ${value}, Index: ${index}`);
  };

  const handleFrequencyChange = (index, field, value) => {
    const updatedFrequencies = frequencies.map((freq, idx) =>
      idx === index ? { ...freq, [field]: Number(value) } : freq
    );
    setFrequencies(updatedFrequencies);
    logEvent('UserForm', 'Frequency Change', `Frequency changed: ${field}, Value: ${value}, Index: ${index}`);
  };

  const handleToggleChange = (index, field) => {
    const updatedToggleStates = toggleStates.map((toggleState, idx) =>
      idx === index ? { ...toggleState, [field]: !toggleState[field] } : toggleState
    );
    setToggleStates(updatedToggleStates);
    logEvent('UserForm', 'Toggle Change', `Toggle changed to: ${updatedToggleStates[index][field] ? 'Yearly' : 'Monthly'}, Index: ${index}, Field: ${field}`);
  };

  const removeField = (index) => {
    const removedPerson = additionalFields[index];
    setAdditionalFields(additionalFields.filter((_, idx) => idx !== index));
    setFrequencies(frequencies.filter((_, idx) => idx !== index));
    setToggleStates(toggleStates.filter((_, idx) => idx !== index));
    logEvent('UserForm', 'Remove Person', `Removed person: ${removedPerson.name}, Age: ${removedPerson.age}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCalculate(additionalFields, frequencies.map((freq, idx) => ({
      phoneCalls: toggleStates[idx].phoneCalls ? freq.phoneCalls : freq.phoneCalls * 12,
      walks: toggleStates[idx].walks ? freq.walks : freq.walks * 12,
      meals: toggleStates[idx].meals ? freq.meals : freq.meals * 12,
      vacations: toggleStates[idx].vacations ? freq.vacations : freq.vacations * 12,
      visits: toggleStates[idx].visits ? freq.visits : freq.visits * 12,
      movies: toggleStates[idx].movies ? freq.movies : freq.movies * 12,
      holidays: freq.holidays,
    })));
  };

  return (
    <DashboardCard title="Enter Your Details">
      {exceeds24Hours && <Alert severity="warning">The total hours per day exceed 24 hours. Please adjust your inputs.</Alert>}
      <form className="form-grid" onSubmit={handleSubmit}>
        <CustomFormLabel htmlFor="date-picker">Birthday</CustomFormLabel>
        <Grid container spacing={2}>
          {/* Existing Fields */}
          <Grid item xs={12} sm={6}>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="MM-dd-yyyy"
              placeholderText="MM-DD-YYYY"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              maxDate={new Date()}
              customInput={
                <TextField
                  fullWidth
                  label="Birthday"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              }
              popperPlacement="bottom-start"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="life-expectancy-slider">Life Expectancy (years)</CustomFormLabel>
            <CustomSlider
              name="lifeExpectancy"
              value={Number(data.lifeExpectancy) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'lifeExpectancy', value } })}
              step={1}
              marks={generateMarks(predefinedOptions.lifeExpectancy)}
              min={70}
              max={90}
              valueLabelDisplay="auto"
              aria-labelledby="life-expectancy-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="retirement-age-slider">Retirement Age</CustomFormLabel>
            <CustomSlider
              name="retirementAge"
              value={Number(data.retirementAge) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'retirementAge', value } })}
              step={1}
              marks={generateMarks(predefinedOptions.retirementAge)}
              min={50}
              max={70}
              valueLabelDisplay="auto"
              aria-labelledby="retirement-age-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="sleep-slider">Daily Sleep Hours</CustomFormLabel>
            <CustomSlider
              name="sleepHours"
              value={Number(data.sleepHours) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'sleepHours', value } })}
              step={1}
              marks={generateMarks(predefinedOptions.sleepHours)}
              min={4}
              max={10}
              valueLabelDisplay="auto"
              aria-labelledby="sleep-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="work-slider">Daily Work Hours</CustomFormLabel>
            <CustomSlider
              name="workHours"
              value={Number(data.workHours) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'workHours', value } })}
              step={1}
              marks={generateMarks(predefinedOptions.workHours)}
              min={0}
              max={12}
              valueLabelDisplay="auto"
              aria-labelledby="work-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="commute-slider">Daily Commute Time (hours)</CustomFormLabel>
            <CustomSlider
              name="commuteTime"
              value={Number(data.commuteTime) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'commuteTime', value } })}
              step={0.5}
              marks={generateMarks(predefinedOptions.commuteTime)}
              min={0}
              max={3}
              valueLabelDisplay="auto"
              aria-labelledby="commute-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="prayer-slider">Daily Prayer Time (hours)</CustomFormLabel>
            <CustomSlider
              name="prayerTime"
              value={Number(data.prayerTime) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'prayerTime', value } })}
              step={0.5}
              marks={generateMarks(predefinedOptions.prayerTime)}
              min={0}
              max={2}
              valueLabelDisplay="auto"
              aria-labelledby="prayer-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="leisure-slider">Leisure Time (hours)</CustomFormLabel>
            <CustomSlider
              name="leisureTime"
              value={Number(data.leisureTime) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'leisureTime', value } })}
              step={0.5}
              marks={generateMarks(predefinedOptions.leisureTime)}
              min={0}
              max={5}
              valueLabelDisplay="auto"
              aria-labelledby="leisure-slider"
            />
          </Grid>
        </Grid>
        <CustomFormLabel htmlFor="family-friends" style={{ marginTop: '20px' }}>Family/Friends</CustomFormLabel>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="immediate-family-slider">Immediate Family Members</CustomFormLabel>
            <CustomSlider
              name="immediateFamilyMembers"
              value={Number(data.immediateFamilyMembers) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'immediateFamilyMembers', value } })}
              step={1}
              marks={generateMarks(predefinedOptions.immediateFamilyMembers)}
              min={0}
              max={12}
              valueLabelDisplay="auto"
              aria-labelledby="immediate-family-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="extended-family-slider">Extended Family Members</CustomFormLabel>
            <CustomSlider
              name="extendedFamilyMembers"
              value={Number(data.extendedFamilyMembers) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'extendedFamilyMembers', value } })}
              step={1}
              marks={generateMarks(predefinedOptions.extendedFamilyMembers)}
              min={0}
              max={20}
              valueLabelDisplay="auto"
              aria-labelledby="extended-family-slider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel htmlFor="close-friends-slider">Close Friends</CustomFormLabel>
            <CustomSlider
              name="closeFriends"
              value={Number(data.closeFriends) || 0} // Ensure the value is a number
              onChange={(e, value) => handleChange({ target: { name: 'closeFriends', value } })}
              step={1}
              marks={generateMarks(predefinedOptions.closeFriends)}
              min={0}
              max={10}
              valueLabelDisplay="auto"
              aria-labelledby="close-friends-slider"
            />
          </Grid>
          {/* Dynamically Added Fields */}
          {additionalFields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5} sm={5}>
                    <TextField
                      fullWidth
                      label="Person Name"
                      value={field.name}
                      onChange={(e) => handleAdditionalFieldChange(index, 'name', e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <TextField
                      fullWidth
                      label="Person Age"
                      type="number"
                      value={field.age}
                      onChange={(e) => handleAdditionalFieldChange(index, 'age', e.target.value)}
                      variant="outlined"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <IconButton color="secondary" onClick={() => removeField(index)}>
                      <IconTrash />
                    </IconButton>
                  </Grid>
                </Grid>
                {/* Frequency Inputs */}
                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                  <Grid item xs={12}>
                    <CustomFormLabel>Current Behavior Frequencies (times per year)</CustomFormLabel>
                  </Grid>
                  {['phoneCalls', 'walks', 'meals', 'vacations', 'visits', 'movies'].map((field, fieldIndex) => (
                    <Grid item xs={12} sm={6} key={field}>
                      <CustomFormLabel htmlFor={`${field}-slider`}>{field.charAt(0).toUpperCase() + field.slice(1)}</CustomFormLabel>
                      <FormControlLabel
                        control={
                          <CustomSwitch
                            checked={toggleStates[index] ? toggleStates[index][field] : true}
                            onChange={() => handleToggleChange(index, field)}
                            name="toggle"
                            color="primary"
                          />
                        }
                        label={toggleStates[index] && toggleStates[index][field] ? 'Yearly' : 'Monthly'}
                      />
                      <CustomSlider
                        name={field}
                        value={Number(frequencies[index]?.[field]) || 0} // Ensure the value is a number
                        onChange={(e, value) => handleFrequencyChange(index, field, value)}
                        step={1}
                        marks={generateMarks(predefinedOptions.frequencies)}
                        min={0}
                        max={50}
                        valueLabelDisplay="auto"
                        aria-labelledby={`${field}-slider`}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel htmlFor="holidays-slider">Holidays</CustomFormLabel>
                    <CustomSlider
                      name="holidays"
                      value={Number(frequencies[index]?.holidays) || 0} // Ensure the value is a number
                      onChange={(e, value) => handleFrequencyChange(index, 'holidays', value)}
                      step={1}
                      marks={generateMarks(predefinedOptions.frequencies)}
                      min={0}
                      max={50}
                      valueLabelDisplay="auto"
                      aria-labelledby="holidays-slider"
                    />
                  </Grid>
                </Grid>
                {field.message && (
                  <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <Typography variant="body1" style={{ color: 'green' }}>{field.message}</Typography>
                    <Typography variant="body2">Phone Calls Left: {field.frequencies.phoneCallsLeft}</Typography>
                    <Typography variant="body2">Walks Left: {field.frequencies.walksLeft}</Typography>
                    <Typography variant="body2">Meals Left: {field.frequencies.mealsLeft}</Typography>
                    <Typography variant="body2">Vacations Left: {field.frequencies.vacationsLeft}</Typography>
                    <Typography variant="body2">Visits Left: {field.frequencies.visitsLeft}</Typography>
                    <Typography variant="body2">Movies Left: {field.frequencies.moviesLeft}</Typography>
                    <Typography variant="body2">Holidays Left: {field.frequencies.holidaysLeft}</Typography>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          ))}
          {/* Submit Button */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" style={{ backgroundColor: '#007bff' }} disabled={loading}>
              {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Calculate'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </DashboardCard>
  );
};

export default UserForm;
