import React, { useState, useEffect } from 'react';
import { Grid, Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import WhatIfScenarios from './WhatIfScenarios';
import ShareableSummary from './ShareableSummary';
import { formatTime } from './utils';
import { logEvent } from './analytics';
import TopCard from './TopCard';
import './theme.css';

// Register the necessary Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

const BUFFER_YEARS = 3;

const Results = ({
  countdown,
  handleExportImage,
  shareableRef,
  summaryVisible,
  additionalFields = [],
  lifeExpectancy = 79,
  frequencies = [],
  data,
  resultsData // Make sure this is passed as a prop
}) => {
  const [modifiedFrequencies, setModifiedFrequencies] = useState(frequencies);

  // Correctly calculate yearsLived and yearsLeft
  const yearsLived = resultsData.age || 0;
  const yearsLeft = lifeExpectancy - yearsLived;

  useEffect(() => {
    console.log("Results Data:", resultsData); // Now resultsData should include all necessary calculations
    console.log("Life Expectancy:", lifeExpectancy);
    console.log("Years Lived:", yearsLived);
    console.log("Years Left:", yearsLeft);
  }, [resultsData, lifeExpectancy]);

  const calculateRemainingYears = (age, lifeExpectancy) => lifeExpectancy - age;

  const tangibleEvents = (years, frequency) => {
    const bufferYears = years < 0 ? BUFFER_YEARS : 0;
    const effectiveYears = years + bufferYears;

    const phoneCalls = Math.max(0, Math.floor(effectiveYears * (frequency.phoneCalls || 1)));
    const walks = Math.max(0, Math.floor(effectiveYears * (frequency.walks || 1)));
    const meals = Math.max(0, Math.floor(effectiveYears * (frequency.meals || 1)));
    const vacations = Math.max(0, Math.floor(effectiveYears * (frequency.vacations || 1)));
    const visits = Math.max(0, Math.floor(effectiveYears * (frequency.visits || 1)));
    const movies = Math.max(0, Math.floor(effectiveYears * (frequency.movies || 1)));
    const holidays = Math.max(0, Math.floor(effectiveYears * (frequency.holidays || 1)));

    return { phoneCalls, walks, meals, vacations, visits, movies, holidays, bufferYears };
  };

  const handleFrequencyChange = (index, activity, newFrequency) => {
    const updatedFrequencies = [...modifiedFrequencies];
    updatedFrequencies[index] = { ...updatedFrequencies[index], [activity]: newFrequency };
    setModifiedFrequencies(updatedFrequencies);

    logEvent('User', 'Change Frequency', `Activity: ${activity}, New Frequency: ${newFrequency}`);
  };

  const calculateTotalExperiences = (yearsLeft, frequency) => {
    const effectiveYears = yearsLeft < 0 ? BUFFER_YEARS : yearsLeft;
    return Math.max(0, Math.floor(effectiveYears * frequency));
  };

  const renderCard = (title, value, icon, backgroundColor, color) => {
    return (
      <TopCard
        title={title}
        value={`${value.years} years`}
        hours={`${value.hours}:${value.minutes}:${value.seconds} Hours`}
        icon={icon}
        backgroundColor={backgroundColor}
        color={color}
      />
    );
  };

  const renderPersonDetails = (field, index) => {
    const yearsLeft = calculateRemainingYears(field.age, lifeExpectancy);
    const events = tangibleEvents(yearsLeft, modifiedFrequencies[index] || {});
    const originalEvents = tangibleEvents(yearsLeft, frequencies[index] || {});

    return (
      <Box key={index} mb={2}>
        <Box mb={2} p={2} className="additional-fields-header">
          {yearsLeft < 0 ? (
            <>
              <Typography variant="body2" color="textSecondary" style={{ marginBottom: '8px' }}>
                {`This person is living beyond the average life expectancy by ${Math.abs(yearsLeft)} years.`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {`You have an additional estimated ${BUFFER_YEARS} years to share meaningful experiences.`}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color="textSecondary">
              {`Years left: ${yearsLeft}`}
            </Typography>
          )}
        </Box>
        <Grid container spacing={2}>
          {Object.entries(events).map(([event, value]) => (
            event !== 'bufferYears' && (
              <Grid item xs={12} key={event}>
                <TopCard
                  title={event.charAt(0).toUpperCase() + event.slice(1)}
                  subtitle={modifiedFrequencies[index][event] !== frequencies[index][event] ? '* (modified results)' : ''}
                  value={`Total: ${calculateTotalExperiences(yearsLeft, modifiedFrequencies[index][event] || 1)}`}
                  backgroundColor="#f0f0f0"
                  color="#333"
                  icon={event} 
                >
                  <WhatIfScenarios
                    activity={event}
                    frequency={modifiedFrequencies[index] ? modifiedFrequencies[index][event] : 1}
                    onFrequencyChange={(activity, newFrequency) => handleFrequencyChange(index, activity, newFrequency)}
                    adjusted={calculateTotalExperiences(yearsLeft, modifiedFrequencies[index][event] || 1) !== originalEvents[event]}
                  />
                </TopCard>
              </Grid>
            )
          ))}
        </Grid>
      </Box>
    );
  };

  const totalHoursPerDay = 24;
  const totalWakingHours = resultsData.totalWakingHours || 0; // Ensure this is pulled from resultsData
  const totalSleepingHours = resultsData.totalSleepingHours || (totalHoursPerDay * 365.25 * (resultsData.lifeExpectancy - resultsData.age)) - totalWakingHours;

  const dataDoughnut = {
    labels: ['Years Lived', 'Years Left'],
    datasets: [
      {
        data: [yearsLived, yearsLeft], // Pass the calculated values
        backgroundColor: ['#cccccc', '#7678ED'], // Grey for years lived, green for years left
        hoverBackgroundColor: ['#aaaaaa', '#3D348B'],
      },
    ],
  };

  const optionsDoughnut = {
    cutout: '70%',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box p={2}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={4}>
          <Doughnut data={dataDoughnut} options={optionsDoughnut} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>Your Remaining Time</Typography>
          <Typography variant="body1">
            Based on an estimated life expectancy of {lifeExpectancy} years, you have lived {yearsLived} years and have {yearsLeft} years left.
          </Typography>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Grid container spacing={3}>
          {[
            { title: 'Waking', value: formatTime(totalWakingHours), icon: 'sun', backgroundColor: '#FFF3E0', color: '#FF6F00' },
            { title: 'Sleeping', value: formatTime(totalSleepingHours), icon: 'moon', backgroundColor: '#E3F2FD', color: '#1E88E5' },
            { title: 'Work', value: formatTime(resultsData.totalWorkHours || 0), icon: 'briefcase', backgroundColor: '#E8F5E9', color: '#388E3C' },
            { title: 'Commute', value: formatTime(resultsData.totalCommuteHours || 0), icon: 'truck', backgroundColor: '#FFF8E1', color: '#FBC02D' },
            { title: 'Prayer/Meditation', value: formatTime(resultsData.totalPrayerHours || 0), icon: 'yoga', backgroundColor: '#EDE7F6', color: '#8E24AA' },
            { title: 'Leisure', value: formatTime(resultsData.totalPersonalActivityHours || 0), icon: 'heart', backgroundColor: '#FFEBEE', color: '#E53935' },
            { title: 'Family', value: formatTime(resultsData.totalFamilyTime || 0), icon: 'users', backgroundColor: '#E0F2F1', color: '#00796B' },
            { title: 'Per Household Member', value: formatTime(resultsData.individualTimePerImmediateFamilyMember || 0), icon: 'home', backgroundColor: '#F9FBE7', color: '#AFB42B' },
            { title: 'Per Relative', value: formatTime(resultsData.individualTimePerExtendedFamilyMember || 0), icon: 'users', backgroundColor: '#F1F8E9', color: '#7CB342' },
            { title: 'Per Close Friend', value: formatTime(resultsData.individualTimePerCloseFriend || 0), icon: 'friends', backgroundColor: '#FFF3E0', color: '#FF6F00' },
          ].map(({ title, value, icon, backgroundColor, color }) => (
            <Grid item xs={12} sm={6} md={4} key={title}>
              {renderCard(title, value, icon, backgroundColor, color)}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4} />
      {additionalFields.map((field, index) => (
        <Accordion key={index} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`} className="custom-card-header">
            <Typography variant="h6" style={{ flex: 1 }}>{field.name}</Typography>
            {calculateRemainingYears(field.age, lifeExpectancy) < 0 && (
              <Typography variant="body2" style={{ marginLeft: '10px', fontStyle: 'italic', fontSize: '0.8rem' }}>(Click to expand for more details)</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {renderPersonDetails(field, index)}
          </AccordionDetails>
        </Accordion>
      ))}
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleExportImage}>
          Export as Image
        </Button>
      </Box>
      <Box mt={4}>
        <ShareableSummary ref={shareableRef} results={countdown} visible={summaryVisible} />
      </Box>
    </Box>
  );
};

export default Results;
